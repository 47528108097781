import React from 'react';
import TextFieldDisplay from '../input/TextFieldDisplay';
import { prettyDateAndTime, prettyDateSimple } from '../../functional/min-support';
import HorizontalSnapper from '../lists/HorizontalSnapper';
import ImageFieldDisplay from '../input/ImageFieldDisplay';
import { connect } from 'react-redux';
import { applicationOpenPDFViewModal } from '../../store/application-store';

class ListItem extends React.Component {

    render () {
        const props = this.props;
        const addStyle = { alignItems: 'stretch' };
        const iconColor = '#934A5F';

        const modeChange = props.modeChange;

        let senderAddress = '';
        if (props.item.fromName) {
            senderAddress += `${ props.item.fromName } <`;
        }

        if (props.item.fromAddress) {
            senderAddress += props.item.fromAddress;
        }

        if (props.item.fromName) {
            senderAddress += '>';
        }

        const style = {};
        if (this.props.active) {
            style.backgroundColor = '#363f5a33';
        }

        if (modeChange) {
            return (
                <div className={'short-list-item'} style={{ ...style, flexDirection: 'row' }} onClick={() => { this.props.onPress(this.props.item); }}>

                    <div style={{ width: '240px', minWidth: '240px' }}>
                        <TextFieldDisplay
                            noBorders
                            noHover
                            oneLine
                            value={props.item.from}/>
                    </div>

                    <div>
                        <TextFieldDisplay
                            noBorders
                            noHover
                            oneLine
                            textStyle={{ fontWeight: '700' }}
                            value={props.item.subject}/>
                    </div>

                    <TextFieldDisplay
                        noBorders
                        noHover
                        oneLine
                        dangerously={true}
                        value={props.item.body}/>

                    <div>
                        <TextFieldDisplay
                            noBorders
                            noHover
                            oneLine
                            value={prettyDateSimple(props.item.created)}/>
                    </div>

                </div>
            );
        }

        return (
            <div className={'short-list-item'} style={style} onClick={() => { this.props.onPress(this.props.item); }}>
                <div className={'short-list-item-container'}>
                    <div className={'short-list-item-container-inner'}>
                        <div style={{ flex: 1, width: '100%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.subject}
                                icon={this.props.active ? 'ft-check-square' : 'ft-square'}
                                style={{ flex: 1 }}/>
                        </div>

                    </div>

                    <div className={'short-list-item-container-inner'}>
                        <div style={{ flex: 1, width: '100%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={props.item.from}
                                icon={'ft-at-sign'}/>
                        </div>
                    </div>

                    <div className={'short-list-item-container-inner'}>
                        <div style={{ flex: 1, width: '100%' }}>
                            <TextFieldDisplay
                                noBorders
                                noHover
                                oneLine
                                value={prettyDateAndTime(props.item.created)}
                                icon={'ft-calendar'}
                                style={{ flex: 1 }}/>
                        </div>
                    </div>

                    <div className={'short-list-item-container-inner'} style={{ paddingTop: 0, paddingBottom: 10 }}>
                        <div style={{ flex: 1, overflow: 'hidden', maxHeight: 48 }}>
                            <div dangerouslySetInnerHTML={{ __html: props.item.body }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className={'sectioned-list-item-compact'}>
                <div className="sectioned-list-item-card" style={addStyle}>

                    <div className={'list-item-section'}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TextFieldDisplay
                                oneLine
                                value={props.item.subject}
                                style={{ flex: 1 }}/>

                            {
                                <div className={'prio-status'} style={{ backgroundColor: iconColor }}>
                                    {'Necitit'}
                                </div>
                            }

                        </div>

                        <TextFieldDisplay
                            oneLine
                            value={prettyDateAndTime(props.item.created)}
                            icon={'ft-calendar'}
                            style={{ marginBottom: 4 }}/>

                        <TextFieldDisplay
                            oneLine
                            value={'Niciun tichet asociat'}
                            icon={'ft-check-square'}
                            style={{ marginBottom: 4 }}/>
                    </div>

                    <div className={'list-item-section'} style={{ borderLeftWidth: 1 }}>
                        <TextFieldDisplay
                            oneLine
                            value={senderAddress}
                            icon={'ft-at-sign'}
                            style={{ marginBottom: 4 }}/>

                        <TextFieldDisplay
                            oneLine
                            label={'client'}
                            value={props.item.fromName}
                            icon={'ft-briefcase'}
                            action={'ft-eye'}
                            onPress={() => {}}
                            style={{ marginBottom: 4 }}/>
                    </div>

                    <div className={'list-item-section-with-button'}>

                        <div className={'inside-list-item-section'}>

                            <TextFieldDisplay
                                oneLine
                                value={props.item.text}
                                icon={'ft-align-left'}
                                style={{ marginBottom: 4 }}/>

                            {
                                props.item.images && props.item.images.length > 0 &&
                                <HorizontalSnapper small items={props.item.images} renderItem={(item) => {
                                    return (
                                        <div style={{ height: 140, width: 240 }}>
                                            <ImageFieldDisplay value={item.services_picture_uid_m} full={item.services_picture_uid_o}/>
                                        </div>
                                    );
                                }}/>
                            }
                        </div>

                        <div className={'item-action-button'}>
                            <div onClick={() => { props.onPress(props.item); }} className={'item-action-button-inside'}>
                                {'Vezi email-ul'}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPDFViewModal: (link) => { return dispatch(applicationOpenPDFViewModal({ link })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
