import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addAction, addNote, editNote, getTicket, markDebugTicket } from '../functional/api';
import { colorByStatus, kStatusAssigned, kStatusScheduled, kStatusStopped } from '../functional/status';
import InternalToolbar from '../components/general/InternalToolbar';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import ImageFieldDisplay from '../components/input/ImageFieldDisplay';
import TimelineList from '../components/lists/TimelineList';
import {
    filter,
    inputValidationStringNonEmpty,
    prettyDate,
    prettyDateAndTime,
    prettyDuration,
    prettyTimeFromServer,
} from '../functional/min-support';
import AddActionModal from '../components/modal/AddActionModal';
import HorizontalSnapper from '../components/lists/HorizontalSnapper';
import { toClientPage } from '../functional/navigation';
import AddEstimativeModal from '../components/modal/AddEstimativeModal';
import AddMailModal from '../components/modal/AddMailModal';
import {
    applicationAddSnack,
    applicationOpenPDFViewModal,
    applicationOpenUpdateModal,
    applicationSetItemPageTitle,
} from '../store/application-store';
import TextFieldInput from '../components/input/TextFieldInput';
import SimpleButton from '../components/input/SimpleButton';
import { kToastActionError, kToastActionWarn } from '../components/general/Toast';
import MarkDebugModal from '../components/modal/MarkDebugModal';

class TicketPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        item: null,
        hasPVI: false,
        search: '',

        paperModal: false,
        emailModalOpen: false,

        emailModalInitialReplyEmail: null,
        emailModalInitialReplyAllEmail: null,
        emailModalInitialForwardEmail: null,

        markDebugModal: false,

        notes: [],
        noteInEdit: null,
        newNote: null,
    };

    state = TicketPage.defaultState;

    localEditNoteNb = 0;

    componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.updateModal && !nextProps.updateModal) {
            this.update();
        }
    }

    componentDidMount () {
        this.props.onSetItemPageTitle();
        this.update();
    }

    generalUpdate = () => {
        this.setState(TicketPage.defaultState, this.update);
    };

    onPVIPressed = (id) => {

    };

    update = () => {
        const key = this.props.match.params.key;

        getTicket(key, (successful, data) => {
            console.log('ticket', data);
            if (successful) {
                this.setState({
                    item: data,
                });
                if (data.notes) {
                    this.setState({
                        notes: data.notes,
                    });
                }
                this.props.onSetItemPageTitle(data.title);
            }
        });
    };

    setNoteInEditBody = (body) => {
        if (this.state.noteInEdit === 'new') {
            this.setState({
                newNote: { info: { body } },
            });
        } else if (this.state.noteInEdit !== null) {
            const notesLocal = this.state.notes;
            for (let i = 0; i < notesLocal.length; ++i) {
                if (notesLocal[ i ]._id === this.state.noteInEdit) {
                    notesLocal[ i ].info.body = body;
                }
            }
            this.setState({ notes: notesLocal });
        }
    }

    saveCurrentNewNote = (immediateCallback) => {
        if (this.state.noteInEdit !== 'new' || !this.state.newNote) {
            return;
        }
        const info = this.state.newNote.info;
        const notes = this.state.notes;
        if (info.body.length <= 0) {
            return;
        }
        addNote(this.props.match.params.key, 'text', info, () => {

        });
        notes.unshift({ _id: this.localEditNoteNb++, type: 'text', info });
        this.setState({ newNote: null, noteInEdit: null, notes }, immediateCallback ? immediateCallback : () => {});
    }

    saveCurrentEditNote = (immediateCallback) => {
        if (this.state.noteInEdit === 'new' || this.state.noteInEdit === null || this.state.newNote) {
            return;
        }
        const note = this.state.notes.find((n) => { return n._id === this.state.noteInEdit; });
        if (!note) {
            return;
        }
        console.log('note.info', note.info);
        editNote(this.state.noteInEdit, note.info, () => {

        });
        this.setState({ newNote: null, noteInEdit: null }, immediateCallback ? immediateCallback : () => {});
    }

    startNewNote = () => {
        this.setState({ newNote: { info: { body: '' } }, noteInEdit: 'new' });
    }

    render () {
        //let actions = this.state.item ? translateTicketActionList(this.state.item.actions) : null;
        let actions = this.state.item ? this.state.item.history : null;
        //let equipments = this.state.item ? this.state.item.equipments : null;
        const emails = this.state.item && this.state.item.emailsDetailed ? this.state.item.emailsDetailed : null;
        const toolbarActions = [
            {
                title: 'Semnaleaza problema',
                onPress: () => {
                    this.setState({ markDebugModal: true });
                },
                icon: { title: 'ft-alert-triangle' },
            },
            {
                title: 'Scrie email',
                onPress: () => {
                    this.setState({ emailModalOpen: true, emailModalInitialReplyEmail: null });
                },
                icon: { title: 'ft-send' },
            },
            // {
            //     title: 'Genereaza vanzare',
            //     onPress: () => {
            //         this.props.toast('Produsele nu sunt configurate.', kToastActionError);
            //     },
            //     icon: { title: 'ft-file-plus' },
            // },
            {
                title: 'Adauga actiune',
                onPress: () => { this.setState({ modalOpen: true }); },
                icon: { title: 'ft-plus' },
            },
        ];

        if (this.state.item) {
            if (this.state.item.status === 'Nou' || this.state.item.status === 'Oprit' || this.state.item.status === 'Atribuit') {
                toolbarActions.push({
                    title: 'Incepe rezolvarea',
                    onPress: () => {
                        addAction({
                            ticketId: this.state.item._id,
                            newStatus: 'In lucru',
                            description: 'Modificare din platforma.',
                            assigned: this.props.user.id,
                        }, () => { this.update(); });
                    },
                    icon: { title: 'ft-play' },
                });
            } else if (this.state.item.status === 'In lucru') {
                toolbarActions.push({
                    title: 'Opreste rezolvarea',
                    onPress: () => {
                        addAction({
                            ticketId: this.state.item._id,
                            newStatus: 'Nou',
                            description: 'Modificare din platforma.',
                            assigned: this.props.user.id,
                        }, () => { this.update(); });
                    },
                    icon: { title: 'ft-pause' },
                });
            }
        }
        const toolbarLabels = [
            {
                connection: 'in lista de actiuni dupa:',
                tags: [ 'Statusul solicitarii', 'Numele utilizatorului', 'Prenumele utilizatorului' ],
            },
        ];
        if (actions && this.state.search.length > 0) {
            actions = filter(actions, [ 'status', 'creatorInfo.fullName' ], this.state.search, true);
        }

        return (
            <React.Fragment>
                <InternalToolbar
                    backButton
                    refresh
                    onRefresh={this.generalUpdate}
                    search
                    searchValue={this.state.search}
                    onSearchChange={(newValue) => { this.setState({ search: newValue }); }}
                    actions={toolbarActions}
                    labels={toolbarLabels}/>

                <div className="w-100 h-100 overflow-hidden d-flex flex-row">

                    <div className="h-100 overflow-scroll" style={{ flex: 1, padding: 20 }}>
                        {
                            this.state.item &&
                            <React.Fragment>
                                <div className={'general-card'}>
                                    <TextFieldDisplay
                                        label={'Denumire'}
                                        value={this.state.item.title}
                                        style={{ marginBottom: 4 }}
                                        action={'ft-edit'}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.title,
                                                'ticket',
                                                'title'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'Status'}
                                        textBackgroundColor={colorByStatus(this.state.item.status)}
                                        textColor={'#ffffff'}
                                        value={this.state.item.status}
                                        icon={'ft-code'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Detalii'}
                                        value={this.state.item.description}
                                        icon={'ft-file-text'}
                                        action={'ft-edit'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            this.props.openUpdateModal(
                                                this.state.item.id,
                                                this.state.item.description,
                                                'ticket',
                                                'description'
                                            );
                                        }}/>

                                    <TextFieldDisplay
                                        label={'Data'}
                                        value={prettyDateAndTime(this.state.item.created)}
                                        icon={'ft-clock'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Operatorul'}
                                        value={this.state.item.creatorInfo.fullName}
                                        icon={'ft-users'}
                                        style={{ marginBottom: 4 }}/>

                                </div>

                                {
                                    this.state.item.debug &&
                                    <TextFieldDisplay
                                        label={'Problema semnalata'}
                                        value={this.state.item.debug}
                                        icon={'ft-alert-triangle'}
                                        style={{ marginTop: 20, backgroundColor: '#f5f14b', borderRadius: 4 }}/>
                                }

                                <div className={'general-card'} style={{ marginTop: 20, marginBottom: 20 }}>
                                    <TextFieldDisplay
                                        label={'Client'}
                                        value={this.state.item.clientInfo.title}
                                        icon={'ft-briefcase'}
                                        action={'ft-eye'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            toClientPage(this.state.item.clientInfo._id);
                                        }}/>

                                    <TextFieldDisplay
                                        label={'Cod Identificare Fiscala'}
                                        value={this.state.item.clientInfo.cid}
                                        icon={'ft-info'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Reg. Com.'}
                                        value={this.state.item.clientInfo.tri}
                                        icon={'blank'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Banca'}
                                        value={this.state.item.clientInfo.bankTitle}
                                        icon={'ft-credit-card'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'IBAN'}
                                        value={this.state.item.clientInfo.bankAccount}
                                        icon={'blank'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Sediu social'}
                                        value={this.state.item.clientInfo.address}
                                        icon={'ft-map-pin'}
                                        style={{ marginBottom: 4 }}/>

                                    <TextFieldDisplay
                                        label={'Telefon'}
                                        value={this.state.item.clientInfo.contactPhone}
                                        icon={'ft-phone'}
                                        style={{ marginBottom: 4 }}/>
                                </div>

                                {
                                    emails && emails.length > 0 &&
                                    <h4>Email-uri</h4>
                                }

                                {
                                    emails && emails.length > 0 &&
                                    emails.map((email) => {
                                        console.log('email', email);
                                        email.body = email.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                                        return (
                                            <div key={email.id} className={'general-card'} style={{ marginBottom: 20 }}>

                                                <TextFieldDisplay
                                                    value={email.from}
                                                    style={{ marginBottom: 4 }}/>

                                                <TextFieldDisplay
                                                    value={email.subject}
                                                    style={{ marginBottom: 4, fontWeight: 600 }}/>

                                                {
                                                    <div style={{ paddingLeft: 10, paddingRight: 10 }} dangerouslySetInnerHTML={{ __html: email.html ? email.html : email.body }}>
                                                    </div>
                                                }

                                                <TextFieldDisplay
                                                    label={'creat la'}
                                                    value={prettyDateAndTime(email.created)}
                                                    style={{ marginBottom: 4 }}/>

                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                                    <SimpleButton value={'Reply'} onPress={() => { this.setState({ emailModalOpen: true, emailModalInitialReplyEmail: email, emailModalInitialReplyAllEmail: null, emailModalInitialForwardEmail: null }); }}/>
                                                    <SimpleButton value={'Reply All'} onPress={() => { this.setState({ emailModalOpen: true, emailModalInitialReplyEmail: null, emailModalInitialReplyAllEmail: email, emailModalInitialForwardEmail: null }); }}/>
                                                    <SimpleButton value={'Forward'} onPress={() => { this.setState({ emailModalOpen: true, emailModalInitialReplyEmail: null, emailModalInitialReplyAllEmail: null, emailModalInitialForwardEmail: email }); }}/>
                                                </div>

                                            </div>
                                        );
                                    })
                                }

                                {
                                    emails && emails.length <= 0 &&
                                    <h4>Niciun email</h4>
                                }

                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>

                    <div className="h-100 overflow-scroll" style={{ width: '30%', padding: 20, paddingLeft: 0 }}>

                        {
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <h4>Notite</h4>
                                        <div>
                                            <SimpleButton value={'Adauga'} onPress={() => {
                                                if (this.state.noteInEdit === 'new') {
                                                    this.saveCurrentNewNote(this.startNewNote);
                                                } else if (this.state.noteInEdit !== null) {
                                                    this.saveCurrentEditNote(this.startNewNote);
                                                } else {
                                                    this.startNewNote();
                                                }
                                            }} icon={'ft-plus'}/>
                                        </div>

                                    </div>
                                    {
                                        this.state.notes.length <= 0 && !this.state.newNote &&
                                            <h5>Nicio notita</h5>
                                    }
                                </div>
                                {
                                    this.state.noteInEdit === 'new' && this.state.newNote !== null &&
                                    <div className={'mb-1'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <TextFieldInput
                                            value={this.state.newNote.info.body}
                                            icon={'ft-edit'}
                                            iconColor={'#934A5F'}
                                            placeholder={'Introduceti notitele'}
                                            onKeyDown={(e) => {
                                                console.log('keydown');
                                                if (e.keyCode === 13) {
                                                    this.saveCurrentNewNote();
                                                    return true;
                                                }
                                            }}
                                            onChange={(newValue) => { this.setNoteInEditBody(newValue); }}/>
                                        <div>
                                            <SimpleButton value={''} onPress={() => {
                                                this.saveCurrentNewNote();
                                            }} icon={'ft-check'} style={{ marginLeft: 4 }}/>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.notes.map((note) => {
                                        if (note._id === this.state.noteInEdit) {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <TextFieldInput
                                                        style={{ flex: 1 }}
                                                        classname={'mb-1 flex-1'}
                                                        value={note.info.body}
                                                        icon={'ft-edit'}
                                                        iconColor={'#934A5F'}
                                                        placeholder={'Introduceti notitele'}
                                                        onKeyDown={(e) => {
                                                            console.log('keydown');
                                                            if (e.keyCode === 13) {
                                                                this.saveCurrentEditNote();
                                                                return true;
                                                            }
                                                        }}
                                                        onChange={(newValue) => { this.setNoteInEditBody(newValue); }}/>

                                                    <div>
                                                        <SimpleButton value={''} onPress={() => {
                                                            this.saveCurrentEditNote();
                                                        }} icon={'ft-check'}/>
                                                    </div>

                                                </div>

                                            );
                                        } else {
                                            return (
                                                <TextFieldDisplay
                                                    classname={'mb-1'}
                                                    value={note.info.body}
                                                    style={{ paddingBottom: 8 }}
                                                    onPress={() => {
                                                        if (this.state.noteInEdit === 'new') {
                                                            this.saveCurrentNewNote(() => {
                                                                this.setState({ noteInEdit: note._id });
                                                            });
                                                        } else if (this.state.noteInEdit !== null && this.state.noteInEdit !== note._id) {
                                                            this.saveCurrentEditNote(() => {
                                                                this.setState({ noteInEdit: note._id });
                                                            });
                                                        } else {
                                                            this.setState({ noteInEdit: note._id });
                                                        }
                                                    }}
                                                    icon={'ft-edit'}/>
                                            );
                                        }
                                    })
                                }
                            </div>
                        }

                        {
                            this.state.item && actions &&
                            <React.Fragment>
                                <TimelineList
                                    items={actions}
                                    renderItem={(item) => {
                                        return (
                                            <div className="general-card card-small-padding" style={{ paddingLeft: 20 }}>
                                                <TextFieldDisplay
                                                    textBackgroundColor={colorByStatus(item.status)}
                                                    textColor={'#ffffff'}
                                                    style={{ marginBottom: 4 }}
                                                    value={item.status}
                                                    icon={'ft-code'}/>

                                                <TextFieldDisplay
                                                    style={{ marginBottom: 4 }}
                                                    value={item.description}
                                                    icon={'ft-align-justify'}/>

                                                <TextFieldDisplay
                                                    style={{ marginBottom: 4 }}
                                                    value={prettyDateAndTime(item.created)}
                                                    icon={'ft-clock'}/>

                                                <TextFieldDisplay
                                                    value={item.creatorInfo.fullName}
                                                    icon={'ft-users'}/>

                                                {
                                                    false && ticketActionAdvancedFields(item, this.onPVIPressed)
                                                }
                                            </div>
                                        );
                                    }}/>
                            </React.Fragment>
                        }

                        {
                            !this.state.item &&
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <div className="spinner_loader secondary_spinner_loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }

                    </div>
                </div>

                {
                    this.state.item &&
                    <AddEstimativeModal
                        open={this.state.estimativeModal}
                        parentKey={this.state.item.id}
                        onClose={() => {
                            this.setState({
                                estimativeModal: false,
                            });
                            //this.props.openPDFViewModal('loading');
                            this.update();
                        }}/>
                }

                {
                    this.state.item &&
                    <MarkDebugModal
                        ticketId={this.props.match.params.key}
                        open={this.state.markDebugModal}
                        onClose={() => {
                            this.setState({
                                markDebugModal: false,
                            });
                            this.update();
                        }}/>
                }

                {
                    this.state.item &&
                    <AddMailModal
                        ticketId={this.props.match.params.key}
                        open={this.state.emailModalOpen}
                        initialReplyEmail={this.state.emailModalInitialReplyEmail}
                        initialReplyAllEmail={this.state.emailModalInitialReplyAllEmail}
                        initialForwardEmail={this.state.emailModalInitialForwardEmail}
                        onClose={() => {
                            this.setState({
                                emailModalOpen: false,
                                emailModalInitialReplyEmail: null,
                            });
                            this.update();
                        }}/>
                }

                {
                    this.state.item &&

                    <AddActionModal
                        fromStatus={this.state.item.status}
                        parentKey={this.state.item._id}
                        open={this.state.modalOpen}
                        onClose={() => {
                            this.setState({
                                modalOpen: false,
                            });
                            this.update();
                        }}/>

                }

            </React.Fragment>
        );
    }
}

export const ticketActionAdvancedFields = (action, onPVIPressed) => {
    switch (action.status) {
    case kStatusStopped:
        return (
            <React.Fragment>
                {
                    action.reason &&
                        <TextFieldDisplay
                            icon={'ft-alert-triangle'}
                            style={{ marginBottom: 4 }}
                            label={'motivul opririi'}
                            value={action.reason}/>
                }
                {
                    action.real_difference &&
                        <TextFieldDisplay
                            icon={'ft-watch'}
                            style={{ marginBottom: 4 }}
                            label={'a stat in lucru'}
                            value={prettyDuration(action.real_difference)}/>
                }
            </React.Fragment>
        );

    case kStatusAssigned:
        return (
            <React.Fragment>
            </React.Fragment>
        );

    case kStatusScheduled:
        return (
            <React.Fragment>
                <TextFieldDisplay
                    icon={'ft-calendar'}
                    label={'programat pentru'}
                    value={`${ prettyDate(action.schedule) } ora ${ prettyTimeFromServer(action.schedule_time) }`}/>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },

        openPDFViewModal: (link) => { return dispatch(applicationOpenPDFViewModal({ link })); },
        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketPage));
