import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleButton from '../input/SimpleButton';

class InternalToolbar extends Component {
    
    static propTypes = {
        search: PropTypes.bool,
        onSearchChange: PropTypes.func,
        searchValue: PropTypes.string,
        
        refresh: PropTypes.bool,
        onRefresh: PropTypes.func,
        
        actions: PropTypes.array,
        labels: PropTypes.array,

        user: PropTypes.object,
        onBack: PropTypes.func,
        history: PropTypes.object,
    };

    constructor (props) {
        super(props);
        this.searchInput = React.createRef();
    }

    componentDidMount () {
        if (this.props.search) {
            // This.searchInput.current.focus();
        }
    }

    render () {
        return (
            <div className={'internal-toolbar'}>
                <div className={'internal-toolbar-container'}>
                    <div className={'internal-toolbar-left-container'}>
                        
                        {
                            this.props.backButton &&
                            <SimpleButton value={'Inapoi'} icon={'ft-arrow-left'} onPress={this.onBack}/>
                        }
    
                        {
                            this.props.refresh &&
                            <SimpleButton value={'Reincarca'} icon={'ft-refresh-ccw'} onPress={this.props.onRefresh}/>
                        }
                    
                        {
                            this.props.search &&
                            <input
                                ref={this.searchInput}
                                placeholder={'Cauta'}
                                value={this.props.searchValue}
                                className={'toolbar-search-input'}
                                onChange={(event) => {
                                    if (this.props.onSearchChange) {
                                        this.props.onSearchChange(event.target.value);
                                    }
                                }}/>
                        }
                        
                    </div>
                
                    <div className="internal-toolbar-right-container">
                        {
                            this.props.actions && this.props.actions.map((action, actionKey) => {
                                return (
                                    <SimpleButton key={action.id} value={action.title} onPress={action.onPress} style={action.style} icon={action.icon ? action.icon.title : null} color={action.icon ? action.icon.color : null} size={action.icon ? action.icon.size : null}/>
                                );
                            })
                        }
                
                    </div>
            
                </div>
                
                {
                    this.props.labels &&
                    <div className={'internal-toolbar-filter-text-display'}>
                        {
                            this.props.labels.map((label, labelKey) => {
                                return (
                                    <React.Fragment key={labelKey}>
                                        {
                                            label.connection &&
                                            <div className={'internal-toolbar-filter-text-description'}>
                                                {label.connection}
                                            </div>
                                        }
                    
                                        {
                                            label.tags && label.tags.map((tag, tagKey) => {
                                                return (
                                                    <div key={`${ labelKey }-${ tagKey }`} className={'internal-toolbar-filter-text-entry'}>
                                                        {tag}
                                                    </div>
                                                );
                                            })
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                }
            </div>
        );
    }

    onBack = () => {
        this.props.history.goBack();
    };
    
}

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.application.drawerOpen,
        
        user: state.application.user,
    };
};

export default connect(mapStateToProps, null)(withRouter(InternalToolbar));