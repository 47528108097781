import {
    routeAffiliateDashboard, routeAffiliatePage, routeClient,
    routeClients,
    routeDashboard,
    routeDownload,
    routeMail,
    routeReports,
    routeStock,
    routeTickets,
    routeUsers,
} from './routes';

const sidebarRoutes = {};

sidebarRoutes.god = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeClients,
    routeStock,
    routeUsers,
    routeDownload,
    {
        title: 'Rapoarte',
        icon: 'ft-bar-chart',
        children: [ routeReports ],
    },
];

sidebarRoutes.admin = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeClients,
    routeStock,
    routeUsers,
    routeDownload,
    {
        title: 'Rapoarte',
        icon: 'ft-bar-chart',
        children: [ routeReports ],
    },
];

sidebarRoutes.operator = [
    routeDashboard,
    routeTickets,
    routeMail,
    routeClients,
    routeStock,
    routeDownload,
];

sidebarRoutes.affiliate = [
    routeAffiliateDashboard,
    routeClients,
];
sidebarRoutes.affiliate_admin = [
    routeDashboard,
    routeAffiliatePage,
    routeTickets,
    routeMail,
    routeClients,
    routeStock,
    routeUsers,
    routeDownload,
    {
        title: 'Rapoarte',
        icon: 'ft-bar-chart',
        children: [ routeReports ],
    },
];

sidebarRoutes.outside = [ routeDashboard ];

export default sidebarRoutes;
