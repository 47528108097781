/**
 * @label Stable Imports
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';

/**
 * @label Functionality Imports
 */

import { getAllApplications, verifyToken } from './functional/api';
import routes, { routeNotFoundProtected, routeNotFoundPublic } from './functional/routes';
import { bindHistory } from './functional/navigation';
import { logicLogout, userLevelTranslate } from './functional/logic';
import { getApplicationId, saveApplicationId } from './functional/storage';

/**
 * @label Component Imports
 */

import LoadingAnimation from './components/general/LoadingAnimation';
import TextFieldDisplay from './components/input/TextFieldDisplay';
import SimpleButton from './components/input/SimpleButton';
import ProtectedLayout from './layout/ProtectedLayout';

/**
 * @label Asset Imports
 */

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './assets/css/flag-icon.min.css';
import './assets/css/themify-icons.css';
import './assets/css/ionicons.min.css';
import './assets/css/ladda.min.css';
import './assets/css/et-line.css';
import './assets/css/feather.css';
import 'font-awesome/css/font-awesome.min.css';
import {
    applicationApplicationSelector,
    applicationApplicationsSelector,
    applicationIsAuthenticatedSelector,
    applicationLogin,
    applicationSetApplication,
    applicationSetApplications,
    applicationUserSelector,
} from './store/application-store';

/**
 *
 * @returns {JSX.Element}
 */

const AppComp = (props) => {

    /**
     * @label Hooks : Start
     */

    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * @label Hooks : End
     */


    /**
     * @label State : Start
     */

    const [ tokenVerified, setTokenVerified ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    /**
     * @label State : End
     */


    /**
     * @label Store : Start
     */

    const isAuthenticated = useSelector(applicationIsAuthenticatedSelector);
    const user = useSelector(applicationUserSelector);
    const application = useSelector(applicationApplicationSelector);
    const applications = useSelector(applicationApplicationsSelector);

    /**
     * @label Store : End
     */


    /**
     * @label Functionality : Start
     */

    const isCurrentPathPublic = (level) => {
        const currentPath = history.location.pathname;
        let found = false;
        if (currentPath === '/' || currentPath === '') {
            found = true;
        } else {
            routes[ level ].some((route) => {
                if (route.public && route.path !== '/' && currentPath.startsWith(route.path)) {
                    found = true;
                    return true;
                }
                return false;
            });
        }
        return found;
    };

    const configureApplication = (tries = 10) => {
        const storageApplicationId = getApplicationId();
        getAllApplications((successful, applications) => {
            // console.log('applications', applications);
            if (successful) {
                dispatch(applicationSetApplications(applications));
            } else {
                // console.log('application retrieved failed, tries left: ', tries);
                if (tries > 0) {
                    setTimeout(() => {
                        configureApplication(tries - 1);
                    }, 300);
                }
            }
        });
    };

    /**
     * @label Functionality : End
     */


    /**
     * @label Effects : Start
     */

    useEffect(() => {
        bindHistory(history);
        configureApplication();

        verifyToken((successful, result) => {
            if (successful) {
                dispatch(applicationLogin(result));
                console.log('translate', userLevelTranslate(result.level));
                if (isCurrentPathPublic(userLevelTranslate(result.level))) {
                    history.push('/dashboard');
                }
            } else {
                dispatch(applicationLogin(null));
                if (!isCurrentPathPublic('outside')) {
                    history.push('/login');
                }
            }
            setTokenVerified(true);
        });

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        configureApplication();
    }, [ isAuthenticated ]);

    /**
     * @label Effects : End
     */


    /**
     * @label Render
     */

    // this.updateCount = this.currentBuildNumber.nb - this.previousBuildNumber.nb;

    const stillLoading = !tokenVerified || loading;
    if (stillLoading) {
        return (
            <div className={'App'} style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <LoadingAnimation/>
            </div>
        );
    } else {
        if (isAuthenticated && user && user.level) {
            if (application) {
                //const documents = this.state.documents.all.count.find((item) => { return item.id === 0; });
                return (
                    <div className={'App darker'}>
                        <ProtectedLayout>
                            <Switch>
                                <Redirect exact from="/" to="/dashboard"/>

                                {
                                    routes[ user.level ].map((route) => {
                                        return (
                                            <Route
                                                exact={true}
                                                path={route.path}
                                                component={route.component}
                                                key={route.id}/>
                                        );
                                    })
                                }

                                <Route
                                    component={routeNotFoundProtected.component}
                                    key={routeNotFoundProtected.id}/>

                            </Switch>
                        </ProtectedLayout>
                    </div>
                );
            } else {
                return (
                    <div className={'App darker'} style={{ backgroundColor: '#363f5a' }}>
                        <div className={'application-choose'}>
                            <div style={{ color: '#ffffff', marginBottom: 10, fontSize: 24 }}>
                                Alege unul din centrele
                            </div>
                            {
                                applications && applications.length > 0 && applications.map((application, index) => {
                                    return (
                                        <div key={application.id} className={'general-card'} style={{ marginTop: 0, marginBottom: 10, minWidth: 400, padding: 20 }}>

                                            <TextFieldDisplay
                                                label={'Titlul centrului'}
                                                value={application.title}
                                                icon={index + 1}
                                                style={{ marginBottom: 4 }}/>

                                            {
                                                application.adminInfo &&
                                                <TextFieldDisplay
                                                    label={'Administratorul centrului'}
                                                    value={application.adminInfo.fullName}
                                                    icon={'ft-user'}
                                                    style={{ marginBottom: 10 }}/>
                                            }

                                            <SimpleButton
                                                value={'Alege centrul'}
                                                icon={'ft-check-circle'}
                                                style={{ fontSize: 14 }}
                                                onPress={() => {
                                                    dispatch(applicationSetApplication(application));
                                                    saveApplicationId(application._id);
                                                }}/>
                                        </div>
                                    );
                                })
                            }

                            <div onClick={() => {
                                logicLogout((successful) => {
                                    if (successful) {
                                        dispatch(applicationLogin(null));
                                        history.push('/logout');
                                    }
                                });
                            }} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#ffffff', marginTop: 10, fontSize: 16 }}>
                                Inapoi la autentificare
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className={'App'}>
                    <Switch>
                        <Redirect exact from="/" to="/login" />
                        {
                            routes.outside.map((route) => {
                                return (
                                    <Route exact={true} path={route.path} component={route.component} key={route.id}/>
                                );
                            })
                        }
                        <Route component={routeNotFoundPublic.component} key={routeNotFoundPublic.id}/>
                    </Switch>
                </div>
            );
        }
    }
};

export default AppComp;
