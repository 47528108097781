import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllMail, invalidateTestEmails, returnMailToInbox, sendMailToSpam } from '../functional/api';
import ListPage from '../layout/page/ListPage';
import { kFilterMailFields, kFilterMailFieldsDefault } from '../functional/constants';
import { withRouter } from 'react-router-dom';

import ListItem from '../components/mail/MailListItem';
import AddMailModal from '../components/modal/AddMailModal';
import { connect } from 'react-redux';
import PagedPage from '../layout/page/PagedPage';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import { compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import {
    applicationAddSnack,
    applicationOpenPDFViewModal,
    applicationOpenUpdateModal,
    applicationSetItemPageTitle,
} from '../store/application-store';
import { toClientPage, toTicketPage, toUserPage } from '../functional/navigation';
import SimpleButton from '../components/input/SimpleButton';
import { kToastActionError } from '../components/general/Toast';
import FileUploadModal from '../components/modal/FileUploadModal';
import MailViewModal from '../components/modal/MailViewModal';

const mailFragments = (folderGroup, totalNb) => {
    return [
        {
            id: 'inbox',
            title: 'IN',
            icon: 'ft-inbox',
            color: '#49aa83',
            label: '',
            type: 'list',
            listFilter: (item) => { return item.folder === 'inbox'; },
        },
        {
            id: 'sent',
            title: 'ST',
            icon: 'ft-send',
            color: '#0b2c56',
            label: '',
            type: 'list',
            listFilter: (item) => { return item.folder === 'sent'; },
        },
        {
            id: 'draft',
            title: 'DR',
            icon: 'ft-edit',
            color: '#b2882f',
            label: folderGroup.draft,
            type: 'list',
            listFilter: (item) => { return item.folder === 'draft'; },
        },
        {
            id: 'template',
            title: 'TM',
            icon: 'ft-grid',
            color: '#6e4a08',
            label: folderGroup.template,
            type: 'list',
            listFilter: (item) => { return item.folder === 'template'; },
        },
        {
            id: 'spam',
            title: 'SP',
            icon: 'ft-alert-circle',
            color: '#540f0f',
            label: '',
            type: 'list',
            listFilter: (item) => { return item.folder === 'spam'; },
        },
    ];
};

class MailPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
        activeItem: null,
        loadedEmail: null,
        uploadOpen: false,

        emailModalInitialReplyEmail: null,
        emailModalInitialReplyAllEmail: null,
        emailModalInitialForwardEmail: null,

        modeOfView: 'panned',
        mailViewModalOpen: false,
        modeOfList: 'third',
    };

    state = MailPage.defaultState;

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(MailPage.defaultState, this.update);
    };

    advanceModeOfList = (current) => {
        if (current === 'first') {
            return 'second';
        } else if (current === 'second') {
            return 'third';
        } else {
            return 'first';
        }
    }

    update = () => {
        getAllMail((successful, data) => {
            if (successful) {
                // console.log('data', data);
                data = data.map((item) => {
                    return {
                        ...item,
                        originalBody: item.body,
                        body: item.body.replace(/<[^>]*>?/gm, ' ').replace(/&nbsp;/g, ''),
                    };
                });
                this.setState({
                    items: data,
                });
            }
        });
    };

    render () {
        const items = this.state.items;
        const folderGroup = {};
        let totalNb = 0;

        if (this.state.items) {
            for (let i = 0; i < items.length; ++i) {
                if (!folderGroup[ items[ i ].folder ]) {
                    folderGroup[ items[ i ].folder ] = 0;
                }
                folderGroup[ items[ i ].folder ] += 1;
                totalNb += 1;
            }
        }

        const tableFields = {
            'subject': {
                title: 'Subiect',
                transform: (item) => { return item.subject; },
                action: (item) => { this.onItemClick(item); },
                compare: compareGeneratorString('subject'),
                icon: 'ft-hash',
            },
            'body': {
                title: 'Detalii',
                transform: (item) => { return <span className={'span-two'}>{item.body}</span>; },
                action: (item) => { this.onItemClick(item); },
                compare: compareGeneratorString('body'),
                icon: 'ft-file-text',
            },
            'received': {
                title: 'Primit',
                transform: (item) => { return prettyDateAndTime(item.created); },
                compare: compareGeneratorString('created'),
                icon: 'ft-clock',
            },
            'from': {
                title: 'De la',
                transform: (item) => { return item.from; },
                compare: compareGeneratorString('from'),
                action: (item) => { },
                icon: 'ft-user',
            },
            'to': {
                title: 'Catre',
                transform: (item) => { return item.to[ 0 ].address; },
                icon: 'ft-briefcase',
            },

            // 'status': {
            //     title: 'Status',
            //     transform: (item) => { return <span style={{ color: colorByStatus(item.status) }}>{item.status}</span>; },
            //     compare: compareGeneratorString('status'),
            //     icon: 'ft-code',
            // },
        };

        if (this.state.modeOfView === 'panned') {
            tableFields.view = {
                title: '',
                transform: (item) => { return this.state.activeItem && this.state.activeItem._id === item._id ? <span style={{ color: '#620707' }}>{'Selectat'}</span> : 'Selecteaza'; },
                action: (item) => { this.onItemClick(item); },
            };
        }

        return (
            <PagedPage
                customRender={this.state.modeOfList === 'second' || this.state.modeOfList === 'third'}
                onRefresh={this.generalUpdate}
                paddingOverall
                deepSearch={true}
                loading={this.state.items == null}
                actions={[
                    {
                        title: 'Scrie email',
                        icon: { title: 'ft-send' },
                        onPress: () => {
                            this.setState({
                                loadedEmail: null,
                                modalOpen: true,
                            });
                        },
                    },
                    {
                        title: 'Sterge email-uri test',
                        icon: { title: 'ft-trash-2' },
                        onPress: () => {
                            invalidateTestEmails((successful) => {
                                this.generalUpdate();
                            });
                        },
                    },
                    {
                        title: 'Baza de date mail-uri',
                        icon: { title: 'ft-upload-cloud' },
                        onPress: () => {
                            this.setState({
                                uploadOpen: true,
                            });
                        },
                    },
                    this.state.modeOfView === 'panned' ?
                        {
                            title: 'Vizualizare completa',
                            icon: { title: 'ft-eye' },
                            onPress: () => {
                                this.setState({
                                    modeOfView: 'fullscreen',
                                });
                            },
                        } :
                        {
                            title: 'Vizualizare separata',
                            icon: { title: 'ft-eye-off' },
                            onPress: () => {
                                this.setState({
                                    modeOfView: 'panned',
                                });
                            },
                        },
                    {
                        title: 'Tipul listei',
                        icon: { title: 'ft-list' },
                        onPress: () => {
                            this.setState({
                                modeOfList: this.advanceModeOfList(this.state.modeOfList),
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            modeChange={this.state.modeOfList === 'third'}
                            active={this.state.activeItem ? this.state.activeItem._id === item._id : false}
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                tableFields={tableFields}
                defaultSort={'created'}
                detailedViewOpen={this.state.activeItem}
                renderDetailedView={this.state.modeOfView !== 'panned' ? null : () => {
                    if (!this.state.activeItem) {
                        return (
                            <div style={{ fontWeight: 600 }}>Selecteaza un email</div>
                        );
                    }
                    return (
                        <React.Fragment>
                            <div className={'general-card'}>

                                {
                                    this.state.activeItem.folder === 'draft' &&
                                    <SimpleButton icon={'ft-edit-2'} value={'Continua email'} style={{ marginBottom: 10 }} onPress={() => {
                                        //toTicketPage(this.state.activeItem._id);
                                        console.log('on click', this.state.activeItem);
                                        this.setState({ loadedEmail: this.state.activeItem, modalOpen: true });
                                    }}/>
                                }

                                {
                                    this.state.activeItem.folder === 'template' &&
                                    <SimpleButton icon={'ft-edit-2'} value={'Incarca sablon'} style={{ marginBottom: 10 }} onPress={() => {
                                        //toTicketPage(this.state.activeItem._id);
                                        this.setState({ loadedEmail: this.state.activeItem, modalOpen: true });
                                    }}/>
                                }

                                <TextFieldDisplay
                                    value={this.state.activeItem.from}
                                    style={{ marginBottom: 4 }}/>

                                <TextFieldDisplay
                                    value={this.state.activeItem.subject}
                                    style={{ marginBottom: 4, fontWeight: 600 }}/>

                                {
                                    true || this.state.activeItem.folder === 'sent' &&
                                        <div style={{ paddingLeft: 10, paddingRight: 10 }} dangerouslySetInnerHTML={{ __html: this.state.activeItem.body }}>
                                        </div>
                                }

                                {
                                    false && this.state.activeItem.folder !== 'sent' &&
                                    <TextFieldDisplay
                                        label={'corpul (protejat)'}
                                        value={this.state.activeItem.body}
                                        icon={'ft-file-text'}
                                        style={{ marginBottom: 4, overflow: 'hidden' }}/>
                                }

                                <div style={{ width: '100%', maxWidth: '100%', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: this.state.activeItem.originalBody }}/>

                                <TextFieldDisplay
                                    value={prettyDateAndTime(this.state.activeItem.created)}
                                    style={{ marginBottom: 4 }}/>

                            </div>

                            {
                                this.state.activeItem && this.state.activeItem.folder === 'inbox' &&
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'column', paddingTop: 10, paddingBottom: 10 }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                                        <SimpleButton value={'Reply'} onPress={() => { this.setState({ modalOpen: true, emailModalInitialReplyEmail: this.state.activeItem, emailModalInitialReplyAllEmail: null, emailModalInitialForwardEmail: null }); }}/>
                                        <SimpleButton value={'Reply All'} onPress={() => { this.setState({ modalOpen: true, emailModalInitialReplyEmail: null, emailModalInitialReplyAllEmail: this.state.activeItem, emailModalInitialForwardEmail: null }); }}/>
                                        <SimpleButton value={'Forward'} onPress={() => { this.setState({ modalOpen: true, emailModalInitialReplyEmail: null, emailModalInitialReplyAllEmail: null, emailModalInitialForwardEmail: this.state.activeItem }); }}/>
                                        <SimpleButton value={'Spam'} onPress={() => {
                                            sendMailToSpam(this.state.activeItem._id, (successful) => {
                                                if (successful) {
                                                    this.props.toast('Mail-ul a fost trimis in spam.');
                                                } else {
                                                    this.props.toast('Trimitere in spam esuata!', kToastActionError);
                                                }
                                                this.update();
                                                this.setState({ activeItem: null });
                                            });
                                        }}/>

                                    </div>

                                </div>
                            }

                            {
                                this.state.activeItem && this.state.activeItem.folder === 'spam' &&
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'column', paddingTop: 10, paddingBottom: 10 }}>

                                    <div>

                                        <TextFieldDisplay
                                            action={'ft-alert-circle'}
                                            icon={'ft-alert-circle'}
                                            label={'Intoarce in inbox'}
                                            value={'Intoarce'}

                                            onPress={() => {
                                                returnMailToInbox(this.state.activeItem._id, (successful) => {
                                                    if (successful) {
                                                        this.props.toast('Mail-ul a fost intors in inbox.');
                                                    } else {
                                                        this.props.toast('Trimitere in inbox esuata!', kToastActionError);
                                                    }
                                                    this.update();
                                                    this.setState({ activeItem: null });
                                                });
                                            }}
                                            style={{ marginBottom: 4 }}/>

                                    </div>

                                </div>
                            }

                        </React.Fragment>
                    );
                }}

                fragments={[
                    {
                        id: 'folder',
                        title: 'Director',
                        icon: 'ft-folder',
                        noDisplay: true,
                        children: mailFragments(folderGroup, totalNb),
                    },
                ]}
                defaultFragment={mailFragments(folderGroup, totalNb)[ 0 ]}

                defaultFields={kFilterMailFieldsDefault}
                allFields={kFilterMailFields}>

                <AddMailModal
                    open={this.state.modalOpen}
                    loadedEmail={this.state.loadedEmail}
                    initialReplyEmail={this.state.emailModalInitialReplyEmail}
                    initialReplyAllEmail={this.state.emailModalInitialReplyAllEmail}
                    initialForwardEmail={this.state.emailModalInitialForwardEmail}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

                <MailViewModal
                    open={this.state.mailViewModalOpen}
                    activeItem={this.state.activeItem}
                    onClose={() => {
                        this.setState({
                            mailViewModalOpen: false,
                        });
                    }}/>

                <FileUploadModal
                    onDone={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    onClose={() => {
                        this.setState({ uploadOpen: false });
                        setTimeout(() => {
                            this.update();
                        }, 1000);
                    }}
                    open={this.state.uploadOpen}/>

            </PagedPage>
        );

        return (
            <ListPage
                onRefresh={this.generalUpdate}
                deepSearch={true}
                loading={this.state.items == null}
                actions={[
                    {
                        title: 'Scrie email',
                        icon: { title: 'ft-send' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                defaultFields={kFilterMailFieldsDefault}
                allFields={kFilterMailFields}>

                <AddMailModal
                    open={this.state.modalOpen}
                    loadedEmail={this.state.loadedEmail}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }

    onItemClick = (item) => {
        //toTicketPage(item._id);
        this.setState({
            activeItem: item,
        });
        if (this.state.modeOfView !== 'panned') {
            this.setState({
                mailViewModalOpen: true,
            });
        }
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        application: state.application.application,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetItemPageTitle: (value) => { return dispatch(applicationSetItemPageTitle(value)); },
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },

        openPDFViewModal: (link) => { return dispatch(applicationOpenPDFViewModal({ link })); },

        toast: (label, action) => { return dispatch(applicationAddSnack({ label, action })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MailPage));
