import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAllTickets, invalidateTestEmails, invalidateTestTickets } from '../functional/api';
import ListPage from '../layout/page/ListPage';
import { kFilterTicketFields, kFilterTicketFieldsDefault } from '../functional/constants';
import AddTicketModal from '../components/modal/AddTicketModal';
import { withRouter } from 'react-router-dom';
import { toClientPage, toTicketPage, toUserPage } from '../functional/navigation';

import ListItem from '../components/ticket/TicketListItem';
import PagedPage from '../layout/page/PagedPage';
import {
    colorByStatus,
    iconByStatus,
    kStatusAwaitsResponse,
    kStatusFinished,
    kStatusInProgress,
    kStatusNew,
    kStatusStopped,
} from '../functional/status';
import { compareGenerator, compareGeneratorString, prettyDateAndTime } from '../functional/min-support';
import TextFieldDisplay from '../components/input/TextFieldDisplay';
import SimpleButton from '../components/input/SimpleButton';
import { applicationOpenUpdateModal, applicationSetItemPageTitle } from '../store/application-store';
import { connect } from 'react-redux';
import Divider from '../components/general/Divider';

const ticketFragments = (statusGroup, totalNb) => {
    return [
        {
            id: kStatusNew,
            title: 'NO',
            icon: iconByStatus(kStatusNew),
            color: colorByStatus(kStatusNew),
            label: statusGroup[ kStatusNew ],
            type: 'list',
            listFilter: (item) => { return item.status === kStatusNew; },
        },
        {
            id: kStatusInProgress,
            title: 'IL',
            icon: iconByStatus(kStatusInProgress),
            color: colorByStatus(kStatusInProgress),
            label: statusGroup[ kStatusInProgress ],
            type: 'list',
            listFilter: (item) => { return item.status === kStatusInProgress; },
        },
        {
            id: kStatusStopped,
            title: 'OP',
            icon: iconByStatus(kStatusStopped),
            color: colorByStatus(kStatusStopped),
            label: statusGroup[ kStatusStopped ],
            type: 'list',
            listFilter: (item) => { return item.status === kStatusStopped; },
        },
        {
            id: kStatusFinished,
            title: 'FN',
            icon: iconByStatus(kStatusFinished),
            color: colorByStatus(kStatusFinished),
            label: statusGroup[ kStatusFinished ],
            type: 'list',
            listFilter: (item) => { return item.status === kStatusFinished; },
        },
        {
            id: 'all',
            title: 'TO',
            icon: iconByStatus('all'),
            color: colorByStatus('all'),
            label: totalNb,
            type: 'list',
            listFilter: (item) => { return true; },
        },
    ];
};

class TicketsPage extends Component {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultState = {
        items: null,
        modalOpen: false,
        activeItem: null,
    };

    state = TicketsPage.defaultState;

    componentDidMount () {
        this.update();
    }

    generalUpdate = () => {
        this.setState(TicketsPage.defaultState, this.update);
    };

    update = () => {
        getAllTickets((successful, data) => {
            if (successful) {
                data = data.map((item) => {
                    item.clientInfo = { title: 'Dummy', _id: 'dummy', cid: 'dummy', tri: 'dummy', bankTitle: 'dummy', bankAccount: 'dummy', address: 'dummy', contactPhone: 'dummy' };
                    return {
                        ...item,
                        creator_name: item.creatorInfo.fullName,
                        client_name: item.clientInfo.title,
                    };
                });
                this.setState({
                    items: data,
                });
            }
        });
    };

    render () {
        const items = this.state.items;
        const statusGroup = {};
        let totalNb = 0;

        if (this.state.items) {
            for (let i = 0; i < items.length; ++i) {
                if (!statusGroup[ items[ i ].status ]) {
                    statusGroup[ items[ i ].status ] = 0;
                }
                statusGroup[ items[ i ].status ] += 1;
                totalNb += 1;
            }
        }

        return (
            <PagedPage
                onRefresh={this.generalUpdate}
                paddingOverall
                deepSearch={true}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga solicitare',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                    {
                        title: 'Sterge solicitari de test',
                        icon: { title: 'ft-trash-2' },
                        onPress: () => {
                            invalidateTestTickets((successful) => {
                                this.generalUpdate();
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            active={this.state.activeItem ? this.state.activeItem._id === item._id : false}
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                tableFields={{
                    'title': {
                        title: 'Solicitare',
                        transform: (item) => { return item.title; },
                        action: (item) => { this.onItemClick(item); },
                        compare: compareGeneratorString('title'),
                        icon: 'ft-hash',
                    },
                    'description': {
                        title: 'Detalii',
                        transform: (item) => { return item.description; },
                        action: (item) => { this.onItemClick(item); },
                        compare: compareGeneratorString('description'),
                        icon: 'ft-file-text',
                    },
                    'created': {
                        title: 'Creat la',
                        transform: (item) => { return prettyDateAndTime(item.created); },
                        compare: compareGeneratorString('created'),
                        icon: 'ft-clock',
                    },
                    'creator_name': {
                        title: 'Operator',
                        transform: (item) => { return item.creator_name; },
                        compare: compareGeneratorString('creator_name'),
                        action: (item) => { console.log('item', item); toUserPage(item.creatorInfo.id); },
                        icon: 'ft-user',
                    },
                    // 'client_name': {
                    //     title: 'Client',
                    //     transform: (item) => { return item.client_name; },
                    //     compare: compareGeneratorString('client_name'),
                    //     action: (item) => { toClientPage(item.clientInfo._id); },
                    //     icon: 'ft-briefcase',
                    // },

                    // 'status': {
                    //     title: 'Status',
                    //     transform: (item) => { return <span style={{ color: colorByStatus(item.status) }}>{item.status}</span>; },
                    //     compare: compareGeneratorString('status'),
                    //     icon: 'ft-code',
                    // },

                    'view': {
                        title: '',
                        transform: (item) => { return this.state.activeItem && this.state.activeItem._id === item._id ? <span style={{ color: '#620707' }}>{'Selectat'}</span> : 'Selecteaza'; },
                        action: (item) => { this.onItemClick(item); },
                    },
                }}
                defaultSort={'created'}
                detailedViewOpen={Boolean(this.state.activeItem)}
                renderDetailedView={() => {
                    if (!this.state.activeItem) {
                        return (
                            <div style={{ fontWeight: 600 }}>Selecteaza o solicitare</div>
                        );
                    }
                    return (
                        <React.Fragment>
                            <SimpleButton icon={'ft-eye'} value={'Vezi solicitarea'} style={{ marginBottom: 10 }} onPress={() => {
                                toTicketPage(this.state.activeItem._id);
                            }}/>
                            <div className={'general-card'}>
                                <TextFieldDisplay
                                    title
                                    value={this.state.activeItem.title}
                                    style={{ marginBottom: 4 }}/>

                                <TextFieldDisplay
                                    textBackgroundColor={colorByStatus(this.state.activeItem.status)}
                                    textColor={'#ffffff'}
                                    value={this.state.activeItem.status}
                                    icon={'ft-code'}
                                    style={{ marginBottom: 4 }}/>

                                <TextFieldDisplay
                                    label={'detalii generale'}
                                    value={this.state.activeItem.description}
                                    icon={'ft-file-text'}
                                    style={{ marginBottom: 4 }}/>

                                {/*<Divider style={{ marginBottom: 4 }}/>*/}

                                <TextFieldDisplay
                                    label={'creat la'}
                                    value={prettyDateAndTime(this.state.activeItem.created)}
                                    icon={'ft-clock'}
                                    style={{ marginBottom: 4 }}/>

                                <TextFieldDisplay
                                    label={'de catre operatorul'}
                                    value={this.state.activeItem.creatorInfo.fullName}
                                    icon={'ft-users'}
                                    style={{ marginBottom: 4 }}/>

                                <Divider style={{ marginBottom: 4 }}/>

                                <TextFieldDisplay
                                    label={'atribuit operatorului'}
                                    value={this.state.activeItem.assignedDetails ? this.state.activeItem.assignedDetails.fullName : 'Neatribuit'}
                                    icon={'ft-users'}
                                    style={{ marginBottom: 4 }}/>

                                {
                                    false &&
                                    <TextFieldDisplay
                                        label={'client'}
                                        value={this.state.activeItem.clientInfo.title}
                                        icon={'ft-briefcase'}
                                        action={'ft-eye'}
                                        style={{ marginBottom: 4 }}
                                        onPress={() => {
                                            toClientPage(this.state.activeItem.clientInfo._id);
                                        }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.cid &&
                                    <TextFieldDisplay
                                        label={'cod identificare fiscala'}
                                        value={this.state.activeItem.clientInfo.cid}
                                        icon={'ft-info'}
                                        style={{ marginBottom: 4 }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.tri &&
                                    <TextFieldDisplay
                                        label={'nr. inregistrare la registrul comertului'}
                                        value={this.state.activeItem.clientInfo.tri}
                                        icon={'blank'}
                                        style={{ marginBottom: 4 }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.bankTitle &&
                                    <TextFieldDisplay
                                        label={'banca'}
                                        value={this.state.activeItem.clientInfo.bankTitle}
                                        icon={'ft-credit-card'}
                                        style={{ marginBottom: 4 }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.bankAccount &&
                                    <TextFieldDisplay
                                        label={'IBAN cont bancar'}
                                        value={this.state.activeItem.clientInfo.bankAccount}
                                        icon={'blank'}
                                        style={{ marginBottom: 4 }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.address &&
                                    <TextFieldDisplay
                                        label={'adresa sediului social'}
                                        value={this.state.activeItem.clientInfo.address}
                                        icon={'ft-map-pin'}
                                        style={{ marginBottom: 4 }}/>
                                }

                                {
                                    false && this.state.activeItem.clientInfo.contactPhone &&
                                    <TextFieldDisplay
                                        label={'nr. telefon contact'}
                                        value={this.state.activeItem.clientInfo.contactPhone}
                                        icon={'ft-phone'}
                                        style={{ marginBottom: 4 }}/>
                                }

                            </div>

                            <SimpleButton icon={'ft-eye'} value={'Vezi solicitarea'} style={{ marginTop: 10 }} onPress={() => {
                                toTicketPage(this.state.activeItem._id);
                            }}/>
                        </React.Fragment>
                    );
                }}

                fragments={[
                    {
                        id: 'status',
                        title: 'Statusul curent',
                        icon: 'ft-code',
                        noDisplay: true,
                        children: ticketFragments(statusGroup, totalNb),
                    },
                ]}
                defaultFragment={ticketFragments(statusGroup, totalNb)[ 0 ]}

                defaultFields={kFilterTicketFieldsDefault}
                allFields={kFilterTicketFields}>

                <AddTicketModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </PagedPage>
        );

        return (
            <ListPage
                onRefresh={this.generalUpdate}
                paddingOverall
                deepSearch={true}
                loading={this.state.items === null}
                actions={[
                    {
                        title: 'Adauga solicitare',
                        icon: { title: 'ft-plus' },
                        onPress: () => {
                            this.setState({
                                modalOpen: true,
                            });
                        },
                    },
                ]}
                allListItems={this.state.items}
                renderListItem={(item) => {
                    return (
                        <ListItem
                            history={this.props.history}
                            item={item}
                            onPress={this.onItemClick}/>
                    );
                }}

                defaultFields={kFilterTicketFieldsDefault}
                allFields={kFilterTicketFields}>

                <AddTicketModal
                    open={this.state.modalOpen}
                    onClose={() => {
                        this.setState({
                            modalOpen: false,
                        });
                        this.update();
                    }}/>

            </ListPage>
        );
    }

    onItemClick = (item) => {
        //toTicketPage(item._id);
        this.setState({
            activeItem: item,
        });
    };

}

const mapStateToProps = (state) => {
    return {
        user: state.application.user,
        updateModal: state.application.modals.updateModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openUpdateModal: (id, previous, type, on) => { return dispatch(applicationOpenUpdateModal({ id, previous, type, on })); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketsPage));
